import '../style/Presentation.css'
import Reservation from './Reservation'
import {NavLink} from "react-router-dom";

function Presentation() {
    function recharge()
    {
        setTimeout( timetime, 500);
        
        function timetime()
        {
         window.location.reload() ;
        }
    }
	return <section className='presentation'>

<h2 className="tpresentation">Salut , C'est Benoit! Je suis développeur front-end spécialisé React!</h2>
<p className="ppresentation"> Je suis diplômé de la formation développeur web(niveau bac+2) de la 3wa academy. Je réalise 
maintenant des sites en fonction de vos envies et de votre budget. Nous pouvons discuter ensemble de vos besoins au cours d'un entretien. 
Pour ce faire, réservez-vous un créneau sur <NavLink to="/reservation" className="lienpresentation"  onClick={() => recharge()}>l'Agenda de réservation</NavLink> 

</p>



    </section>
}

export default Presentation