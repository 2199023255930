import Month from './Month'
import Heure from './Heure'
import '../style/reservation.css'
import axios from 'axios'
import React, { useEffect, useState } from 'react';




function Reservation(Constpresenter, OpenHeure, setOpenHeure, AgendaVisible, setAgendaVisible) {

  
  
    const [appState, setAppState] = useState();
    
    
    const [Annee, setAnnee] = useState(2021);
    useEffect(() => {
      const apiUrl = 'https://apiportfolio.benoitgoffinetportfolio.fr/API/index.php';
      axios.get(apiUrl).then((resp) => {
        const allPersons = resp.data;
        setAppState(allPersons);
      });
    }, [setAppState]);
  
    let date = new Date()
   
    let datedate = date.getMonth()
    const [Changemonth, updateChangemonth] = useState(datedate);
    const [Day, updateDay] = useState(0);
    const [Dayfull, updateDayfull] = useState(false);
    let dayweek = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"];
    let dayweekmobile = ["L", "M", "M", "J", "V", "S", "D"];
    let daydaycoef = [0,7,14,21,28,35];
    const lesmois =["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const lesmoisfr=["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"];
    let nombd=[];
    let heurebd=[];
    let jourbd=[];
    let moisbd=[];
    let tableaudonne;
    let tableaudonnef;
    let jourbdnombre=[];
    let bheure=[];
    let nombdb=[];
   let anneetab=[];
    var divstyle = {
      cursor: "not-allowed",
      backgroundColor: "#6c757d",
    };
    if(appState){
       tableaudonne=Object.values(appState);
        appState.map(reserve=>nombd.push(reserve[1])-
        heurebd.push(reserve[2])-
        jourbd.push(reserve[3])-
        moisbd.push(reserve[4])-
        anneetab.push(reserve[5])
        )
  
    }
   
    
    function findday(day, coef){
    
 

if(dayweek.indexOf(day["day"])+1+day["coef"]-newdate>0)
      {
        updateDay(dayweek.indexOf(day["day"])+1+day["coef"])
        Constpresenter["setOpenHeure"](true)  
    }
    else
    {
      console.log("<0")
    }
  }

    function changesens(sens)
{
	if(sens=="moins")
	{
		if(Changemonth==0)
		{
			updateChangemonth(11)
      setAnnee(Annee-1)
		}
		else
		{
			updateChangemonth(Changemonth-1)	
		}
	}
	if(sens=="plus")
	{
		
		if(Changemonth==11)
		{
			updateChangemonth(0)
      setAnnee(Annee+1)
		}
		else
		{
			updateChangemonth(Changemonth+1)	
		}
	}
	
}
let finjour;
if(Changemonth==0 || Changemonth==2 || Changemonth==4 || Changemonth==6|| Changemonth==7|| Changemonth==9 || Changemonth==11)
{
    finjour=31;
}
else if(Changemonth==1)
{
    finjour=28;
}
else
{
    finjour=30; 
}

datedate = new Date(lesmois[Changemonth]+"1, "+Annee.toString());
       
        
         var newdate = datedate.getDay()-1;
         let jourmois=[]
         for(var i=0;i<jourbd.length;i++)
         {
          if(moisbd[i]==lesmois[Changemonth] && anneetab[i]==Annee.toString())
          {
     jourmois.push(jourbd[i])
     bheure.push(heurebd[i])
     nombdb.push(nombd[i])
          }
         }
         
         for(var i=0;i<jourmois.length;i++)
         {
           jourbdnombre.push(parseInt(jourmois[i]))
         }
         
     let occurrences = { };
     for (var i = 0; i < jourbdnombre.length; i++) {
     
        occurrences[jourbdnombre[i]] = (occurrences[jourbdnombre[i]] || 0) + 1;
     }
    

    return  <>
    {Constpresenter["Constpresenter"] &&<section className="rreservation">
      
{Constpresenter["AgendaVisible"] && 
<section className="bodyreservation">
    <div className='reservation'>
      <h1 className="h2reservation">Reservez un créneau !</h1>
        <div className="changsens">
        <section className="changesens">
		<div className="changesensdiv"  onClick={() => changesens("moins")}><i class="fas fa-arrow-left"></i>
			
		</div>
		<p>{lesmoisfr[Changemonth]+" "+Annee}</p>
        <div className="changesensdiv" onClick={() => changesens("plus")}><i class="fas fa-arrow-right"></i>
			
		</div>
		
        </section>
</div>

<table>
  
  {dayweek.map((day, index) => <td ><tr className="trd"><p className="pc">{day}</p><p className="mobile">{dayweekmobile[index]}</p></tr>
  
  {daydaycoef.map((coef) =>(dayweek.indexOf(day)+1+coef)<=finjour+newdate && <tr className="tr" style= {dayweek.indexOf(day)+1+coef-newdate<=0 ? divstyle : occurrences[dayweek.indexOf(day)+1+coef-newdate]>7 ? {backgroundColor:"#dc3545"} : occurrences[dayweek.indexOf(day)+1+coef-newdate]>0 ? {backgroundColor:"#ffc107"} : {backgroundColor:"#28a745"}}
   onClick={() => findday({day, coef})} >{ dayweek.indexOf(day)+1+coef-newdate>0 ? dayweek.indexOf(day)+1+coef-newdate : <i class="fas fa-times"></i>}</tr>
  )}
  
  </td>)}
</table>
</div>
</section>
}
<section className="agenda">
{Constpresenter["OpenHeure"] &&
<Heure Changemonth={Changemonth} updateChangemonth={updateChangemonth} Day={Day-newdate} updateDay={updateDay} lesmois={lesmois} moisbd={moisbd} jourmois={jourmois} heurebd={bheure}
 nombd={nombdb} tableaudonne={tableaudonne} Dayfull={Dayfull} updateDayfull={updateDayfull} AgendaVisible={Constpresenter["AgendaVisible"]} setAgendaVisible={Constpresenter["setAgendaVisible"]} 
 lesmoisfr={lesmoisfr} Annee={Annee} Openfeedback={Constpresenter["Openfeedback"]} updateOpenfeedback={Constpresenter["updateOpenfeedback"]} OpenHeure={Constpresenter["OpenHeure"]} setOpenHeure={Constpresenter["setOpenHeure"]}/>
}

 
    </section>
    </section> 
}

</>
}
export default Reservation