import "../style/feedback.css"
import {NavLink} from "react-router-dom";


function Feedback({Openfeedback, updateOpenfeedback, AgendaVisible, setAgendaVisible, OpenHeure, setOpenHeure}) {
    setTimeout( time, 3000);
   function time()
   {
    window.location.reload() ;
   }
    function fretour()
    {
       
        updateOpenfeedback(false)
        setAgendaVisible(true)
        setOpenHeure(false)
    }
    return <><section className="feedback">
        <p>Votre réservation a été prise en compte.
       Je reviens vers vous dans les plus brefs délais</p>
      
        <NavLink to="/" className="linkretour" onClick={() => fretour()}>Revenir à l'accueil</NavLink>
    </section>
    </>
    
}

export default Feedback