import '../style/Skills.css'
import {NavLink} from "react-router-dom";

function Skills() {
	return <section className='skills'>

<div className="columnskills" >
    <h2 className="titleskills">Développeur Front-end</h2>
    <p className="pskills">Je crée une interface visible pour vos client pour mettre en avant votre activité.</p>
    <h3 className="titlelanguage">Langage</h3>
    <p className="language">HTML, CSS, SASS, JAVASCRIPT, REACT</p>
    <h3 className="titlelanguage"><NavLink to="/travaux" className="lienstravaux">Travaux récents</NavLink></h3>
    <p className="tlanguage">Le Zoo = REACT JS (rooteur, axios), bootstrap</p>
    <p className="tlanguage">Portfolio(celui-ci)= REACT JS(axios, hooks, rooteur)</p>
    <p className="tlanguage">La Boussole = JAVASCRIPT(POO), CSS, HTML</p>
    <p className="tlanguage">Le Démineur = JAVASCRIPT, CSS, HTML</p>
    <p className="tlanguage">Plateforme ADAM = JAVASCRIPT, CSS, HTML</p>
</div>

<div className="columnskills" >
<h2 className="titleskills" >Développeur Back-end</h2>
<p className="pskills">Je suis polyvalent et je peux donc vous créer un back office pour votre site</p>
<h3 className="titlelanguage">Langage</h3>
<p className="language">PHP, MYSQL</p>
<h3 className="titlelanguage"><NavLink to="/travaux" className="lienstravaux">Travaux récents</NavLink></h3>
<p className="tlanguage">Zoo = API REST PHP(POO, MVC), MYSQL</p>
    <p className="tlanguage">Portfolio = API REST PHP(POO, MVC), MYSQL</p>
    <p className="tlanguage">La Boussole = PHP(POO, MVC), MYSQL</p>
    
</div>



    </section>
}

export default Skills