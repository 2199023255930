import "./nav.css"
import logo from "../assets/benbo.png"
import React, { useEffect, useState } from 'react';
import {NavLink} from "react-router-dom";
function Nav({Constpresenter, updateConstpresenter, OpenHeure,
     setOpenHeure, Openfeedback, updateOpenfeedback,
     Animactiv, setAnimactiv, AgendaVisible, setAgendaVisible}) {
    const [Modal, updateModal]=useState(false);
    function timetime()
    {
     window.location.reload() ;
    } 
    function closeheure()
    {
        setTimeout( timetime, 500); 
    }
	function Modalfunction(lien)
    {
       
        setAnimactiv(true)
        if(Modal==true)
        {
            updateModal(false)
            updateConstpresenter(true)
            
        }
        else
        {
            updateModal(true)
            updateConstpresenter(false)
            
        }
        if(lien=="reservation")
        {
            setTimeout( timetime, 500);
        
       
        }
    }
	return <div><div classNameName='nav'>
        
<nav >

    <ul>
    <li className="lienaccueil" onClick={() => Modalfunction()}><img src={logo} alt="web" ></img><div className="divmobile">{Modal ? <i class="fa fa-times" aria-hidden="true" id="croix"></i> : <i class="fas fa-bars" id="menu"></i>}</div> 
     </li>
        <li><NavLink to="/"className="anav" >Accueil</NavLink></li>
        <li> <NavLink to="/presentation" exact className="anav">Présentation</NavLink></li>
        <li> <NavLink to="/travaux" exact className="anav">Travaux</NavLink></li>
        <li> <NavLink to="/reservation" exact className="anav" onClick={() => closeheure()}>Réservation</NavLink></li>
        <li><a href="http://portfolio.benoitgoffinetportfolio.fr/CV/CV.pdf"
 className="anav" target="_blank" >CV</a></li>
    </ul>
    {Modal &&
    <ul className="ulmobile">
    <li><NavLink to="/" className="amobile" onClick={() => Modalfunction()}>Accueil</NavLink></li>
        <li><NavLink to="/presentation" className="amobile" onClick={() => Modalfunction()} >Présentation</NavLink></li>
        <li><NavLink to="/travaux" className="amobile" onClick={() => Modalfunction()}>Travaux</NavLink></li>
        <li><NavLink to="/reservation" className="amobile" onClick={() => Modalfunction("reservation")} >Réservation</NavLink></li>
        <li><a href="portfolio.benoitgoffinetportfolio.fr/CV/CV.pdf" rel="noreferrer"
target="_blank" className="anav"  >CV</a></li>
    </ul>}
</nav>
</div>

	</div>
}

export default Nav