import '../style/info.css'
import React, { useEffect, useState } from 'react';

function Info(openinfo,updateOpeninfo) {
    function closeinfo()
		{
			openinfo["updateOpeninfo"](false)
          
		}
    useEffect(() => {
      document.title = "Informations"
    })
return	<div onClick={closeinfo} >
<form >
<button onClick={closeinfo}><i class="fas fa-times"></i></button>
  <h2>Informations</h2>

<div className="infodebase">
<div className="divform">
<label className="labelnom">nom</label><input name="nom" className="inputnom"
value="Goffinet" ></input>
</div>
<div className="divform">
<label className="labelprenom">prenom</label><input value="Benoit"></input>
</div>
<div className="divform">
<label className="labeltelephone">telephone</label><input value="0608900285" ></input>
</div>
<div className="divform">
<label className="labelmail">mail</label><input value="benoitgoffinet@live.fr"></input>
</div>
</div>

</form>
</div>
}

export default Info