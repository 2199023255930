import '../style/Skills.css'
import axios from "axios"
import React, { Component } from 'react';

class Axios extends Component {
    state = {
        reservation : null
    }
    
    componentDidMount = ()=> axios.get('http://localhost/API/index.php').then(reponse=>
    this.setState({reservation:reponse.data}));
	render(){
        return <div>
    {
      this.state.reservation && this.state.reservation.map(reserve=>
        {
            return <h1>{reserve[0]} - {reserve[1]} - {reserve[2]} - {reserve[3]} - {reserve[4]}</h1>
        })
  }

        </div>
    }





    
}

export default Axios;