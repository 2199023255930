import { useState, useEffect } from 'react'
import Banner from './Banner'
import Presentation from './Presentation'
import logo from '../assets/benbo.png'
import Cart from './Cart'
import Footer from './Footer'
import Skills from './Skills'
import Presenter from './Presenter'
import Nav from './Nav'
import Working from './Working'
import ShoppingList from './ShoppingList'
import Reservation from './Reservation'
import Axios from './Axios'
import axios from "axios"
import Info from './Info'
import {Switch, Route, browserHistory} from "react-router-dom";
import '../style/Layout.css'
import '../style/Banner.css'
import '../style/site.css'



function Site() {
    const savedCart = localStorage.getItem('cart')
    const [cart, updateCart] = useState(savedCart ? JSON.parse(savedCart) : [])
    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart))
    }, [cart])
    const [openinfo, updateOpeninfo] = useState(false)
    const [Constpresenter, updateConstpresenter] = useState(true)
    const [OpenHeure, setOpenHeure] = useState(false);
    const [Openfeedback, updateOpenfeedback]=useState(false);
    const [AgendaVisible, setAgendaVisible] = useState(true);
    const [Animactiv, setAnimactiv] = useState(false);
    function Openinfo()
    {
        updateOpeninfo(true)
    }
    
    function closeinfo()
    {
        updateOpeninfo(true)
    }
return (
    <div className="bodydiv">
       
        
        <Nav Constpresenter={Constpresenter} updateConstpresenter={updateConstpresenter} OpenHeure={OpenHeure} setOpenHeure={setOpenHeure} Openfeedback={Openfeedback} updateOpenfeedback={updateOpenfeedback} 
        AgendaVisible={AgendaVisible} setAgendaVisible={setAgendaVisible}
        Animactiv={Animactiv} setAnimactiv={setAnimactiv}/>
       
       
       <Switch>
           <Route path="/" exact render={() => <Banner Animactiv={Animactiv}/>} />
           <Route path="/presentation" render={() => <Presenter Constpresenter={Constpresenter}/>} />  
           <Route path="/travaux" render={() => <Working Constpresenter={Constpresenter}/>} />  
           <Route path="/reservation" render={() => <Reservation Constpresenter={Constpresenter} OpenHeure={OpenHeure} setOpenHeure={setOpenHeure} Openfeedback={Openfeedback} updateOpenfeedback={updateOpenfeedback}
           AgendaVisible={AgendaVisible} setAgendaVisible={setAgendaVisible}/>} /> 
          <Route path="/" render={() => <div className="erreur">ERREUR 404</div>} />
        </Switch>
       
    </div>
)
}
export default Site