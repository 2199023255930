import Presentation from "./Presentation"
import Skills from './Skills'
import Banner from './Banner'
import Working from './Working'
function Presenter(Constpresenter={Constpresenter}) {

	return <>
     {Constpresenter["Constpresenter"] &&
     <>
     <Presentation />
        
        <Skills/>
        </>
     } 
     </>
}

export default Presenter