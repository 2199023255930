import '../style/Banner.css'
import React, { useEffect, useState } from 'react';
import logo from '../assets/benbo.png'
import figure1 from '../assets/figure1.png'
import figure2 from '../assets/figure2.png'
import figure3 from '../assets/figure3.png'
import figure4 from '../assets/figure4.png'
import figure5 from '../assets/figure5.png'
import figure6 from '../assets/figure6.png'
import figure7 from '../assets/figure7.png'
import figure8 from '../assets/figure8.png'
import figure9 from '../assets/figure9.png'
import triangle1 from '../assets/triangle1.png'
import triangle2 from '../assets/triangle2.png'
import triangle3 from '../assets/triangle3.png'
import triangle4 from '../assets/triangle4.png'


function Banner({ Animactiv }) {
	const [Topimageun, setTopimageun] = useState(10);
	const [Leftimageun, setLeftimageun] = useState(10);
	const [Leftimagedeux, setLeftimagedeux] = useState(80);
	const [Leftimagetrois, setLeftimagetrois] = useState(30);
	const [Leftimagequatre, setLeftimagequatre] = useState(60);
	const [Topimagedeux, setTopimagedeux] = useState(30);
	const [Topimagetrois, setTopimagetrois] = useState(60);
	const [Topimagebas, setTopimagebas] = useState(75);
	const [Stopboug, setStopboug] = useState(false);
	const [Stopimag, setStopimag] = useState(false);
	const [Stop, setStop] = useState(false);
	var changeimageun;
	var changeimagedeux ;
	var changeimagetrois;
	var changeimagequatre ;
	var hautimageun;
	var hautimagedeux ;
	var hautimagetrois;
	var hautimagequatre ;
	var intervalID ;
	let coef=0;
	let coef2=0;
	useEffect(() => {
		document.title = "Portfolio Benoit Goffinet"
	  })
	  function bougediv(event)
	  {
		
		if(Stopboug==false)
		{
			var X =event.clientX
			var Y =event.clientY
			console.log(X);
			console.log(Y);
			setLeftimageun(10+(X/210))
			 setTopimageun(10+(Y/150))
			 setLeftimagedeux(80+(X/480))
			 setLeftimagetrois(30+(X/240))
			 setLeftimagequatre(60+(X/400))
			 setTopimagebas(75+(Y/450))
			 setTopimagedeux(30+(Y/260))
			 setTopimagetrois(60+(Y/380))
		}
		else
		{
			
		}
		
	  }
	  
	  if (Animactiv==true && Stop ==false)
	  {
		
		  animation()
		  setStop(true)
	  }
	  function animation()
	  {
        
		 setStopboug(true)
		 changeimageun = (0-Leftimageun)/14;
		 changeimagedeux = (100-Leftimagedeux)/14;
		 changeimagetrois = (0-Leftimagetrois)/14;
		 changeimagequatre = (100-Leftimagequatre)/14;
		
		 intervalID =  setInterval(modifplace,20)
		
	  }
	  function modifplace()
	  {
		  if(coef<14)
		  {
			console.log("modifplace")
			coef++
		   setLeftimageun(Leftimageun+(changeimageun*coef))
		   setLeftimagedeux(Leftimagedeux+(changeimagedeux*coef))
		   setLeftimagetrois(Leftimagetrois+(changeimagetrois*coef))
		   setLeftimagequatre(Leftimagequatre+(changeimagequatre*coef))
		  }
		
		
		else
		{
			
             setStopimag(true)
			 clearInterval(intervalID)
			console.log("setintervalstop")
		
		}
	

	  }
	  const image1style={
	  top:parseFloat(Topimageun)+"%",
	  left:parseFloat(Leftimageun)+"%",
	}
	const image2style={
		top:parseFloat(Topimageun)+"%",
		left:parseFloat(Leftimagedeux)+"%",
	  }
	  const image3style={
		top:parseFloat(Topimagedeux)+"%",
		left:parseFloat(Leftimagetrois)+"%",
	  }
	  const image4style={
		top:parseFloat(Topimagedeux)+"%",
		left:parseFloat(Leftimagequatre)+"%",
	  }
	  const image5style={
		top:parseFloat(Topimagetrois)+"%",
		left:parseFloat(Leftimagetrois)+"%",
	  }
	  const image6style={
		top:parseFloat(Topimagetrois)+"%",
		left:parseFloat(Leftimagequatre)+"%",
	  }
	  const image7style={
		top:parseFloat(Topimagebas)+"%",
		left:parseFloat(Leftimageun)+"%",
	  }
	  const image8style={
		top:parseFloat(Topimagebas)+"%",
		left:parseFloat(Leftimagedeux)+"%",
	  }
	 
	  
	return <div className='lmj-banner' onMouseMove={bougediv} onClick={animation}>
     
	<h1 className='lmj-title' >Développeur Front-End</h1>
	<div className="divimage" >
			<img src={logo} alt='benoit goffinet' className='lmj-logo' />
	</div>
	{Stopimag==false &&
	<div className="lesimages">
	<img src={triangle1} alt='benoit goffinet' className='image1' style={image1style} />
	<img src={triangle2} alt='benoit goffinet' className='image2' style={image2style}/>
	<img src={figure1} alt='benoit goffinet' className='image3'  style={image3style}/>
	<img src={figure5} alt='benoit goffinet' className='image4' style={image4style}/>
	<img src={figure7} alt='benoit goffinet' className='image5' style={image5style}/>
	<img src={figure8} alt='benoit goffinet' className='image6' style={image6style}/>
	<img src={triangle3} alt='benoit goffinet' className='image7' style={image7style}/>
	<img src={triangle4} alt='benoit goffinet' className='image8' style={image8style}/>
	</div>}
	</div>
	
}

export default Banner