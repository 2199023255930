import { useState } from 'react'
import Choicename from './Choicename'


function Heure({ Changemonth, updateChangemonth, Day, updateDay, lesmois, moisbd, jourmois, heurebd, nombd, tableaudonne,Dayfull, updateDayfull, AgendaVisible,
     setAgendaVisible, lesmoisfr, Annee, Openfeedback, updateOpenfeedback, OpenHeure, setOpenHeure }) {
         console.log(Openfeedback)
    const [Openchoice, updateOpenchoice]=useState(false);
    const [Time, updateTime]=useState(0);
    const [Month, updateMonth]=useState(lesmois[Changemonth]);
   let moisfr=lesmoisfr[Changemonth];
    const horaire = [9, 10, 11, 12, 14, 15, 16, 17];
    let jourreservation = [];
    let jourreservationnumber = [];
  let infobdopen=false;
    let jourbdnunber=[];
    let indexnumber=[];
    var divStyle = {
        cursor: 'pointer',
        backgroundColor: "#28a745",
      };
      var divStyleautre = {
        cursor:"not-allowed",
        backgroundColor: "#dc3545",
      };
     
    for(var i=0;i<jourmois.length;i++)
    {
      jourbdnunber.push(parseInt(jourmois[i]))
    }
    if(jourbdnunber.indexOf(Day)!=-1 && moisbd.indexOf(lesmois[Changemonth])!=-1)
    {
        infobdopen=true
        for(var i=0;i<jourbdnunber.length;i++)
        {
            if(jourbdnunber[i]==Day)
            {
              
            jourreservation.push(heurebd[i])
            indexnumber.push(i)
            }
            
        }
        for(var i=0;i<jourreservation.length;i++)
        {
            jourreservationnumber.push(parseInt(jourreservation[i]))
        }
       
    }
    
    
    function res()
    {
       
     if(indexnumber.length<=8)
     {
         Dayfull=true
     }
    }
    function hourchoice(time){
        updateOpenchoice(true)
        updateTime(time)
updateMonth(lesmois[Changemonth]);
setAgendaVisible(false);
    }
   
    return<>
{AgendaVisible &&
    <section className="heure">{Day+lesmois[Changemonth].indexOf(jourreservation)!=-1 && res()}
<div className="heurediv">
       <h1 className="titreheure">{Day} {moisfr} {Annee}</h1>
<table>
    <td>
        <tr>Heure</tr>
        {horaire.map((heure, index)=>
        <tr key={index}  value={heure}  style={{backgroundColor:"#6c757d"}}>{heure} h  </tr>)
        }
      
    </td>
    <td>
        <tr className="trname">__</tr>
        {infobdopen==true ?
        horaire.map((heure, index)=>
        jourreservationnumber.indexOf(heure)!=-1 ?
       <tr key={index} style={divStyleautre} className="trname">{nombd[indexnumber[jourreservationnumber.indexOf(heure)]]}</tr>:
       <tr key={index} onClick={() => hourchoice({heure}) }style={divStyle} className="trname">Libre</tr>
       ) : 
       horaire.map((heure, index2)=>
           <tr key={index2} onClick={() => hourchoice({heure}) } value={heure} style={divStyle } className="trname">Libre</tr>
           )}
    
    
       
    </td>
   
   
     
</table>
</div>
</section>
}

{Openchoice && <Choicename Day={Day} Time={Time} Month={Month} Openchoice={Openchoice}
updateOpenchoice={updateOpenchoice} AgendaVisible={AgendaVisible} setAgendaVisible={setAgendaVisible} moisfr={moisfr} Annee={Annee}
Openfeedback={Openfeedback} updateOpenfeedback={updateOpenfeedback} OpenHeure={OpenHeure} setOpenHeure={setOpenHeure}/>}


</>

    
}


export default Heure