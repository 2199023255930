import "../style/Choicename.css";
import axios from 'axios'
import React, { useEffect, useState } from 'react';
import Benoit from "../assets/benbo.png"
import Feedback from './feedback'

function Choicename(Day, Time, Month, Openchoice, updateOpenchoice, AgendaVisible,setAgendaVisible, moisfr, Annee, Openfeedback, updateOpenfeedback,
 OpenHeure, setOpenHeure ){
  const [Name, updateName]=useState("");
  
  const [Mail, updateMail]=useState("");
  
  const [Motif, updateMotif]=useState("");
  const [Form, updateForm]=useState(true);
useEffect(() => {
  document.title = "Reservations"
})
function  envoieform(e)
{
    
    
    e.preventDefault();
   
     var mesDonnees = new FormData();
     mesDonnees.set("heure", Day["Time"]["heure"]);
     mesDonnees.set("jour", Day["Day"]);
     mesDonnees.set("mois", Day["Month"]);
     mesDonnees.set("nom", Name.value);
     mesDonnees.set("annee", Day["Annee"]);
     var mesDonne = new FormData();
     mesDonne.set("mail", Mail.value);
     mesDonne.set("motif", Motif.value);


axios({
     method: 'post',
     url: 'https://apiportfolio.benoitgoffinetportfolio.fr/API/ajoutervols.php',
     data: mesDonnees
   })
   .then(function (response) {
       console.log(response);
     })
     .catch(function (error) {
       console.log(error);
   });
   
    
 

axios({
 method: 'post',
 url: 'https://apiportfolio.benoitgoffinetportfolio.fr/API/info.php',
 data: mesDonne
})
.then(function (response) {
   console.log(response);
 })
 .catch(function (error) {
   console.log(error);
});
Day["updateOpenfeedback"](true)
updateForm(false)

}
function handleChangename(e) {
  updateName({value: e.target.value});
  
}

  

function handleChangemail(e) {
  updateMail({value: e.target.value});
 
}
function handleChangemotif(e) {
  updateMotif({value: e.target.value});
  
}

    return <>
    {Form &&<section className="thereservation">
        <div className="inforeservation">
            <div className="topinfo">
                   <div className="developpeur">
                       <img src={Benoit}></img>
                       <div>
                           <p className="pdev">Développeur <span className="spandev">Web</span></p>
                           <p className="pnom">Benoit Goffinet</p>
                       </div>
                   </div>
            </div>
            <div className="backinfo">
                <h2>Prise de rendez-vous</h2>
                <p><span><i class="fas fa-clock"></i></span>  30-45 min</p>
                <p><span><i class="far fa-calendar"></i></span>  {Day["Time"]["heure"]}:00 - {Day["Time"]["heure"]}:45, {Day["Day"]} {Day["moisfr"]} {Day["Annee"]}</p>
            </div>
            </div>
            <div className="formulaire">
            <form onSubmit={envoieform}>
            <h2>Indiquez vos informations</h2>
            <div className="divform">
              
              
  <label className="labelnom">Nom *</label><input name="nom" className="inputnom"
  value={Name.value} onChange={handleChangename} required></input>
 </div>
  <div className="divform">
  <label className="labelmail">Mail *</label><input value={Mail.value} onChange={handleChangemail} required></input>
  </div>
  
  <div className="divformt">
  <label>Motif du rendez-vous</label><textarea value={Motif.value} onChange={handleChangemotif}></textarea>
  </div>
  <div className="boutons">
  <button>Confirmer</button>
  </div>
  </form>
              

        </div>


    </section>}
  {Day["Openfeedback"] && <Feedback Openfeedback={Day["Openfeedback"]} updateOpenfeedback={Day["updateOpenfeedback"]} AgendaVisible={Day["AgendaVisible"]}
  setAgendaVisible={Day["setAgendaVisible"]} OpenHeure={Day["OpenHeure"]} setOpenHeure={Day["setOpenHeure"]}/>}  
</>
}





export default Choicename