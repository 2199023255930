import Site from "./Site"
import {BrowserRouter} from "react-router-dom";
import {hashHistory} from "react";
import { HashRouter } from 'react-router-dom'


function App() {
  return (
    <div>
      <HashRouter forceRefresh={true} >
      
        <Site />
      </HashRouter>
    </div>
  );
}

	

export default App

