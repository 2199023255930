import '../style/month.css'
import { useState } from 'react'




function Month({ Changemonth, updateChangemonth, lesmois, appState }) {
	
function changesens(sens)
{
	if(sens=="moins")
	{
		if(Changemonth==0)
		{
			updateChangemonth(11)
		}
		else
		{
			updateChangemonth(Changemonth-1)	
		}
	}
	if(sens=="plus")
	{
		
		if(Changemonth==11)
		{
			updateChangemonth(0)
		}
		else
		{
			updateChangemonth(Changemonth+1)	
		}
	}
	
}
	return ( 
        <section className="changesens">
		<div className="changesensdiv"  onClick={() => changesens("moins")}><i class="fas fa-arrow-left"></i>
			
		</div>
		<p>{lesmois[Changemonth]}</p>
        <div className="changesensdiv" onClick={() => changesens("plus")}><i class="fas fa-arrow-right"></i>
			
		</div>
		
        </section>
	)
}

export default Month