import '../style/working.css'
import boussole from '../assets/laboussole.png'
import musique from '../assets/musique.png'
import demineur from '../assets/demineurimage.png'
import zoo from '../assets/zoo.png'
import larcat from '../assets/larcat.png'

function Working(Constpresenter={Constpresenter}) {
   
	return <>
      {Constpresenter["Constpresenter"] &&
    <section className='working'>

<div className="bleue"></div>
</section>
}
{Constpresenter["Constpresenter"] &&
    <div className="divworking">
    <h1 className="titleworking">Travaux récents</h1>
           <div className="bordure">
          <div className="theworking">
          <h3 className="nom">La Boussole</h3>
          <div className="info">
          <p className="languep">CSS, JAVASCRIPT(POO), PHP(POO, MVC), MYSQL</p>
          <p className="expliquep">Site de réservation de gîte réalisé pour une association</p>
          <p className="acces">Accès réservation: identifiant = room | code = room</p>
          <p className="acces">Accès administration: identifiant = admin | code = admin</p>
          </div>
          <a href="https://boussole.benoitgoffinetportfolio.fr/la_boussole/" rel="noreferrer" target="_blank">
             <img src={boussole} alt="site de réservation"></img>
              </a>
          </div>
          </div>
          <div className="bordure">
          <div className="theworking">
          <h3 className="nom">Plateformae ADAM</h3>
          <div className="info">
          <p className="languep">HTML, CSS(SASS), JAVASCRIPT(POO)</p>
          <p className="expliquep">Site d'apprentissage réalisé pour un particulié(Je ne présente qu'une page par souci de confidentialité)</p>
          </div>
          <a href="https://solfege.benoitgoffinetportfolio.fr/exercicemusique/view/" rel="noreferrer" target="_blank">
             <img src={musique} alt="site d'apprentissage"></img>
              </a>
          </div>
          </div>
          <div className="bordure">
          <div className="theworking">
          <h3 className="nom">Le Démineur</h3>
          <div className="info">
          <p className="languep">HTML, CSS, JAVASCRIPT</p>
          <p className="expliquep">Le cultissime Démineur remasterisé</p>
          </div>
          <a href="https://demineur.benoitgoffinetportfolio.fr/demineur/" rel="noreferrer" target="_blank">
             <img src={demineur} alt="demineur remasterisé"></img>
              </a>
          </div>
          </div>
          <div className="bordure">
          <div className="theworking">
          <h3 className="nom">Larcat</h3>
          <div className="info">
          <p className="languep">CSS, JAVASCRIPT(POO), PHP(POO, MVC), MYSQL</p>
          <p className="expliquep">Site de réservation de gîte réalisé pour une association</p>
          <p className="acces">Accès réservation: identifiant = room | code = room<br></br>
                Accès administration: identifiant = admin | code = admin</p>
                </div>
          <a href="https://larcat.benoitgoffinetportfolio.fr/la_boussole/" rel="noreferrer" target="_blank">
             <img src={larcat} alt="site de réservation"></img>
              </a>
          </div>
          </div>
          <div className="bordure">
          <div className="theworking">
          <h3 className="nom">Le ZOO</h3>
          <div className="info">
          <p className="languep">REACT JS (rooteur), bootstrap</p>
          <p className="expliquep">Site fictif d'entrainement React</p>
          </div>
          <a href="https://zoo.benoitgoffinetportfolio.fr/" rel="noreferrer" target="_blank">
             <img src={zoo} alt="demineur remasterisé"></img>
              </a>
          </div>
          </div>
          </div>
}  
    </>
}

export default Working